<template>
  <div class="permission">
    <div class="text">
      暂无权限！
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.permission{
  width: 100%;
  padding: 150px 50px !important;
  background-color: #fff;
  .text {
    text-align: center;
    font-size: 24px;
    line-height: 400px;
  }
}
</style>